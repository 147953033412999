import React from "react"
import Spacer from "../components/spacer/Spacer";
import Centered from "../components/centered/Centered";
import Page from "../components/page/Page";
import "../styles/index.scss";
import InvitationForm from "../components/invitation-form/InvitationForm";

const formStyle = {
  maxWidth: "370px",
  margin: "auto",
}

// markup
const JoinWaitlistPage = () => {
  return (
    <Page>
      <Spacer amount="30" hideOnNarrowDevices={true} />

      <Centered className="max-width-spacing-screen-normal">
        <InvitationForm title="Sign up to the Waitlist" actionLabel="Join Waitlist">
          <p className="grey-300">Sign up for our waitlist to get early access and exclusive offers. If we love your work, we will contact you to be a beta tester.</p>
        </InvitationForm>
        <p className="grey-300 text-small" style={formStyle}>By signing up you are also accepting to receive our newsletter and marketing emails.</p>
      </Centered>
    </Page>
  )
}


export default JoinWaitlistPage
